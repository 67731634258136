import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Canvas } from "./Canvas";

const scrollToContent = () => {
  window.scrollTo({
    top: window.innerHeight,
    behavior: "smooth",
  });
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Canvas />
    <div className="text-above-canvas">
      <div
        className="title"
        style={{
          fontFamily: "'Dancing Script', sans-serif",
          fontStyle: "italic",
          color: "black",
          // fontSize: "10em",
          zIndex: 2,
        }}
      >
        foom.
      </div>
      <div
        className="subtitle"
        style={{
          fontFamily: "'Kode Mono', sans-serif",
          fontStyle: "italic",
          color: "black",
          zIndex: 2,
          textAlign: "center",
        }}
      >
        investing in founders accelerating the timeline.
      </div>
      <button onClick={scrollToContent} style={{ cursor: "pointer", background: "none", border: "none", color: "black", fontSize: "2em", position: "absolute", bottom: "20px" }}>
        &#8964;
      </button>
    </div>
    <div className="text-above-canvas details-container" style={{
      position: "relative",
      width: "100%",
      textAlign: "center", zIndex: 2, minHeight: "100vh",
      display: "flex", flexDirection: "column",
      justifyContent: "center",
      fontFamily: "'Kode Mono', sans-serif",
      overflow: "scroll"
    }}>
      <details style={{ marginBottom: "1em", textAlign: "left" }}>
        <summary style={{ cursor: "pointer" }}>what is foom?</summary>
        <p style={{ fontSize: "1em" }}>
          the sound made when artificial intelligence suddenly becomes <a href="foom.png" target="_blank" rel="noopener noreferrer">extremely powerful</a>. and alex reibman's angel fund.
        </p>
      </details>
      <details style={{ marginBottom: "1em", textAlign: "left" }}>
        <summary style={{ cursor: "pointer" }}>what is the thesis?</summary>
        <p style={{ fontSize: "1em" }}>invest in skilled technical founders building the most ambitious projects of their careers.</p>
      </details>
      <details style={{ marginBottom: "1em", textAlign: "left" }}>
        <summary style={{ cursor: "pointer" }}>what are the terms?</summary>
        <p style={{ fontSize: "1em" }}>
          1) $1-10k SAFE checks. can also connect to institutional capital via scouting depending on your stage.<br />
          2) an open line to talk shop, tricks of the trade, or <a href="https://x.com/AlexReibman/status/1774179498969497798?s=20" target="_blank" rel="noopener noreferrer">going viral</a>.<br />
          3) broader plugs to the sf ai community.
        </p>
      </details>
      <details style={{ marginBottom: "1em", textAlign: "left" }}>
        <summary style={{ cursor: "pointer" }}>how does the process work?</summary>
        <p style={{ fontSize: "1em" }}>1) send a completed yc application as a notion/docs page <a href="https://forms.gle/rM1oJbGtvYJEhRu66">here</a>.<br />
          2) will try to review in 0-72 hours. (please bubble it up if no reply).<br />
          3) if it's good, hop on a short call to discuss ins/outs. you will get feedback same day. </p>
      </details>
      <footer style={{ textAlign: "center", marginTop: "1em", marginBottom: "1em", fontSize: "1em", fontFamily: "Kode Mono", position: "relative", bottom: 0, width: "100%" }}>
        prompted with <span style={{ color: "red" }}>&lt;3</span> by <a href="https://x.com/alexreibman">alex reibman</a>
      </footer>
    </div>
  </React.StrictMode>
);
reportWebVitals();
