import React, { useEffect, useRef } from "react";

class Particle {
    constructor(canvas) {
      this.canvas = canvas;
      this.x = Math.random() * this.canvas.width;
      this.y = Math.random() * this.canvas.height;
      this.velocity = -(Math.random() * 4 + 1); // Initial velocity, now negative to reverse direction
      this.size = Math.random() * 5 + 1; // Size of the particle
    }
  
    update() {
      this.x += this.velocity;
      this.velocity -= 0.1; // Deceleration, to maintain reverse direction
      if (this.x < 0) { // Reset particle once it goes off screen on the left
        this.x = this.canvas.width;
        this.y = Math.random() * this.canvas.height;
        this.velocity = -(Math.random() * 4 + 1); // Reset velocity, keeping it negative
      }
    }
  
    draw(ctx) {
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.size, 0, 2 * Math.PI);
      ctx.fillStyle = 'gray';
      ctx.fill();
    }
  }

export const Canvas = () => {
    const canvasRef = useRef(null);
    const particles = useRef([]);
  
    useEffect(() => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight*2;
  
      // Initialize particles
      const initParticles = (num) => {
        particles.current = [];
        for (let i = 0; i < num; i++) {
          particles.current.push(new Particle(canvas));
        }
      };
  
      initParticles(10); // Initialize 10 particles
  
      const animate = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        particles.current.forEach(particle => {
          particle.update();
          particle.draw(ctx);
        });
        requestAnimationFrame(animate);
      };
  
      animate();
  
      // Handle window resize
      window.addEventListener('resize', () => {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight*2;
        initParticles(10); // Re-initialize particles on resize
      });
  
      return () => window.removeEventListener('resize', initParticles);
    }, []);
  
    return <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0 }} />;
  };